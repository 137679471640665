import {
  Title,
  YesNo,
  PrivateAnnualIncome,
  PrivateInvestingPlan,
  PrivateNetWorth,
  PEPCheckStatus,
  EmploymentStatus,
  OIDIdentificationStatus,
} from 'api';

export const getPEPCheckStatusOptions = () => {
  return Object.values(PEPCheckStatus).map((val) => ({
    label: val.replaceAll('_', ' '),
    value: val,
  }));
};

export const getOIDIdentificationStatusOptions = () => {
  return Object.values(OIDIdentificationStatus).map((val) => ({
    label: val,
    value: val,
  }));
};

export const getYesNoOptions = () => {
  return Object.values(YesNo).map((val) => ({
    label: val,
    value: val,
  }));
};

export const getPrivateAnnualIncomeOptions = () => {
  return Object.values(PrivateAnnualIncome).map((val) => ({
    label: val,
    value: val,
  }));
};

export const getPrivateInvestingPlanOptions = () => {
  return Object.values(PrivateInvestingPlan).map((val) => ({
    label: val,
    value: val,
  }));
};

export const getPrivateNetWorthOptions = () => {
  return Object.values(PrivateNetWorth).map((val) => ({
    label: val,
    value: val,
  }));
};

export const getEmploymentStatusOptions = () =>
  Object.values(EmploymentStatus).map((val) => ({
    label: val,
    value: val,
  }));

export const getTitleOptions = () =>
  Object.entries(Title)
    .filter((el) => el[1] !== Title.MX)
    .map(([name, val]) => ({
      label: `${name}.`,
      value: val,
    }));
