import { ChangeEvent, useCallback } from 'react';
import { Divider, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { FieldProps } from 'formik';

import { withFormLabel } from './withFormLabel';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    primaryOptions?: Array<{ label: string; value: string }>;
    options: Array<{ label: string; value: string; disabled?: boolean }>;
  };

export const Select = withFormLabel((props: Props) => {
  const { disabled, field, error = false, primaryOptions, options, placeholder, ...rest } = props;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (field?.onChange) field?.onChange(e);
      if (rest.onChange) rest.onChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field?.onChange, rest.onChange],
  );

  return (
    <TextField
      select
      disabled={disabled}
      error={error}
      size="small"
      variant="outlined"
      {...field}
      {...rest}
      onChange={onChange}
      SelectProps={{
        displayEmpty: true,
        renderValue: (val) => {
          const value = val ?? field.value;
          if (!value) return placeholder;
          return options.find((option) => option.value === value)?.label;
        },

        ...rest.SelectProps,
      }}
    >
      {primaryOptions?.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
      {primaryOptions && <Divider />}
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
});
