/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface FileMetaAdmin {
  /** @format date-time */
  created: string;
  id: string;
  name: string;
  documentType: 'NONE' | 'KOPIEAUSWEIS' | 'Investor Agreements' | 'KYC Record';
  tenantId?: string;
}

export interface SearchResultAdmin {
  files: FileMetaAdmin[];
  nextPageToken: string;
}

export interface FileMetaBase {
  name: string;
  relation: 'User' | 'KycRecord' | 'Investor' | 'DealProvider' | 'Borrower' | 'Deal';
  relationId: string;
  documentType: 'NONE' | 'KOPIEAUSWEIS' | 'Investor Agreements' | 'KYC Record';
  tenantId: string;
  totalInvestmentAmount?: number;
}

export interface FieldModification {
  fieldId: string;
  setTextValues?: string[];
  setSelectionValues?: string[];
  setIntegerValues?: string[];
  setDateValues?: string[];
  setUserValues?: string[];
  unsetValues?: boolean;
}

export interface LabelModification {
  labelId: string;
  fields?: FieldModification[];
  removeLabel?: boolean;
}

export interface FileUploadDto {
  content: File;
  meta: FileMetaBase;
  labels?: LabelModification[];
}

export interface SearchCriteria {
  documentType?: 'NONE' | 'KOPIEAUSWEIS' | 'Investor Agreements' | 'KYC Record';
  relationType: 'User' | 'KycRecord' | 'Investor' | 'DealProvider' | 'Borrower' | 'Deal';
  relationId: string;
  tenantId?: string;
  fileNameExact?: string;
  fileNamePartial?: string;
  totalInvestmentAmount?: number;
}

export interface SortCriteriaEntry {
  name: 'created' | 'modified';
  order: 'DESC' | 'ASC';
}

export interface SearchDto {
  searchCriteria: SearchCriteria;
  orderCriteria: SortCriteriaEntry[];
  pageSize?: number;
  nextPageToken?: string;
}

export interface FileUpdate {
  labels?: LabelModification[];
  name?: string;
  trashed?: boolean;
}

export interface FolderSearchAdmin {
  relation: 'User' | 'KycRecord' | 'Investor' | 'DealProvider' | 'Borrower' | 'Deal';
  relationId: string;
  documentType?: 'NONE' | 'KOPIEAUSWEIS' | 'Investor Agreements' | 'KYC Record';
  tenantId?: string;
}

export interface ProblemDocument {
  status: number;
  title: string;
  detail?: string;
  instance?: string;
  type?: string;
}

export interface AdminApiControllerListLabelsParams {
  pageSize: number;
  nextPageToken: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Documents API
 * @version 1.0
 * @contact
 *
 * The Documents API.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags files
     * @name AdminApiControllerUploadFile
     * @request POST:/api/files/upload
     * @secure
     */
    adminApiControllerUploadFile: (data: FileUploadDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name AdminApiControllerDownload
     * @request GET:/api/files/{id}/download
     * @secure
     */
    adminApiControllerDownload: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/${id}/download`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name AdminApiControllerDownloadZip
     * @request POST:/api/files/download/zip
     * @secure
     */
    adminApiControllerDownloadZip: (data: SearchDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/download/zip`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name AdminApiControllerUpdate
     * @request POST:/api/files/{id}/update
     * @secure
     */
    adminApiControllerUpdate: (id: string, data: FileUpdate, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/${id}/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name AdminApiControllerList
     * @request POST:/api/files/search
     * @secure
     */
    adminApiControllerList: (data: SearchDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name AdminApiControllerListLabels
     * @request GET:/api/files/labels
     * @secure
     */
    adminApiControllerListLabels: (query: AdminApiControllerListLabelsParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/labels`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name AdminApiControllerGetLinkToDirectory
     * @request POST:/api/files/directoryUrl
     * @secure
     */
    adminApiControllerGetLinkToDirectory: (data: FolderSearchAdmin, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/files/directoryUrl`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
