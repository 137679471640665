import { useAsync, useAsyncCallback } from 'react-async-hook';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { TenantCreateDTO, apiClient } from 'api';
import { Layout, Title } from 'components';
import { AddTenantWidget } from './AddTenantWidget';

export const TenantsPage = () => {
  const { result, execute: fetchTenants } = useAsync(
    async () => await apiClient.api.tenantControllerFindAll(),
    [],
  );

  const createTenant = useAsyncCallback(async (tenant: TenantCreateDTO) => {
    await apiClient.api.tenantControllerCreateOne(tenant);
    await fetchTenants();
  });

  return (
    <Layout>
      <Title name="Tenants">
        <AddTenantWidget onSubmit={createTenant.execute} />
      </Title>
      <TableContainer component={Paper} sx={{ width: 'auto' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.data?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link to={`/tenants/${row.id}`}>{row.id}</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};
