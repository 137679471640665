import { useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, useFormik, FormikContext, FormikHelpers } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { toast } from 'react-toastify';

import {
  apiClient,
  BusinessAnnualIncome,
  BusinessInvestingPlan,
  BusinessKycRecord,
  BusinessNetWorth,
  Country,
  UpdateBusinessKycRecordDto,
  YesNo,
} from 'api';
import { Title, Card, PageLoader, PEPCheckHistoryWidget } from 'components';
import { KycFileAction } from 'components/page-parts';
import {
  AdminApprovalForm,
  CompanyRecordEditForm,
  CompanyRecordEditFormValues,
} from 'components/form-parts';
import { BankVerificationHistoryWidget } from 'components/BankVerificationHistoryWidget';
import { OIDIdentificationHistoryWidget } from 'components/OIDIdentificationHistoryWidget';

const convertFromPayloadToValues = (payload: BusinessKycRecord): CompanyRecordEditFormValues => {
  return {
    id: payload.id,
    tenantId: payload.tenantId,
    companyName: payload.companyName ?? '',
    companyUID: payload.companyUID ?? '',
    industry: payload.industry ?? '',
    legalStructure: payload.legalStructure ?? '',
    phoneNumber: payload.contactPerson.phoneNumber ?? '',
    politicalExposure: payload.politicalExposure,
    businessPlannedInvestment: payload.businessPlannedInvestment,
    businessAnnualIncome: payload.businessAnnualIncome,
    businessNetWorth: payload.businessNetWorth,
    moneyOrigination: payload.moneyOrigination ?? '',

    country: payload.address.country ?? undefined,
    street: payload.address.street ?? '',
    zipcode: payload.address.zipcode ?? '',
    city: payload.address.city ?? '',
    streetNr: payload.address.streetNr ?? '',
    confirmedInvalid: payload.address.confirmedInvalid ?? false,
  };
};

const convertFromValuesToPayload = (
  values: CompanyRecordEditFormValues,
): UpdateBusinessKycRecordDto => {
  return {
    companyName: values.companyName || null,
    companyUID: values.companyUID || null,
    industry: values.industry || null,
    legalStructure: values.legalStructure || null,
    contactPerson: {
      phoneNumber: values.phoneNumber || null,
    },
    address: {
      country: (values.country?.shortName as unknown as Country) ?? null,
      street: values.street || null,
      zipcode: values.zipcode || null,
      city: values.city || null,
      streetNr: values.streetNr || null,
      confirmedInvalid: values.confirmedInvalid || false,
    },
  };
};

export const CompanyDetailsPage = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [record, setRecord] = useState<BusinessKycRecord | null>(null);
  const [edit, setEdit] = useState(false);

  const { loading, error } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFineOne(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setRecord(data as BusinessKycRecord);
  }, [id]);

  const updateRecord = useAsyncCallback(async (changes: UpdateBusinessKycRecordDto) => {
    const { data } = await apiClient.api.kycRecordControllerUpdateOneBusiness(id, changes, {
      headers: { 'x-tenant-id': tenantId },
    });

    setRecord(data);
    toast.success('KYC record is updated');
  });

  const handleSubmit = async (
    values: CompanyRecordEditFormValues,
    formikHelpers: FormikHelpers<CompanyRecordEditFormValues>,
  ) => {
    setEdit(false);
    await updateRecord.execute(convertFromValuesToPayload(values));
    formikHelpers.resetForm();
  };

  const initialValues = useMemo(
    () => (record ? convertFromPayloadToValues(record) : ({} as CompanyRecordEditFormValues)),
    [record],
  );

  const formik = useFormik<CompanyRecordEditFormValues>({
    enableReinitialize: true,
    initialValues: initialValues!,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const handleCancel = () => {
    setEdit(false);
    formik.resetForm();
  };

  if (error) {
    return <div>Record not found</div>;
  } else if (!record || loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Title name={`Company ${record?.companyName ?? ''}`} />
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Card>
            <Card.Header title="Quick look" />

            <Card.Body></Card.Body>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <Card.Header title="Onboarding status" />

            <Card.Body></Card.Body>
          </Card>
        </Grid>

        <AdminApprovalForm />

        {record?.id && tenantId && (
          <Grid item xs={12}>
            <KycFileAction kycRecordId={record.id} tenantId={tenantId} />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormikContext.Provider value={formik}>
            <Form>
              <Card>
                <Card.Header title="Company details" />

                <Card.Body>
                  <CompanyRecordEditForm
                    edit={edit}
                    addressVerifiedAt={record?.addressVerifiedAt}
                  />
                </Card.Body>

                <Card.Footer sx={{ flexDirection: 'row', justifyContent: 'flex-end', gap: '16px' }}>
                  {edit ? (
                    <>
                      <Button key="cancel" variant="outlined" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button type="submit">Save</Button>
                    </>
                  ) : (
                    <Button onClick={() => setEdit(true)} disabled={updateRecord.loading}>
                      Edit
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            </Form>
          </FormikContext.Provider>
        </Grid>
        <PEPCheckHistoryWidget />
        <OIDIdentificationHistoryWidget />
        <BankVerificationHistoryWidget />
      </Grid>
    </>
  );
};
