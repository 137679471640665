import { useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, useFormik, FormikContext, FormikHelpers } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Country,
  EducationLevel,
  PrivateKycRecord,
  UpdatePrivateKycRecordDto,
  apiClient,
} from 'api';
import { Title, Card, PageLoader, PEPCheckHistoryWidget } from 'components';
import { KycFileAction } from 'components/page-parts';
import {
  AdminApprovalForm,
  PrivateRecordEditForm,
  PrivateRecordEditFormValues,
} from 'components/form-parts';
import { OIDIdentificationHistoryWidget } from 'components/OIDIdentificationHistoryWidget';
import { BankVerificationHistoryWidget } from 'components/BankVerificationHistoryWidget';

const convertFromPayloadToValues = (payload: PrivateKycRecord): PrivateRecordEditFormValues => {
  const dob = dayjs(payload.contactPerson.dateOfBirth);

  return {
    id: payload.id,
    tenantId: payload.tenantId,
    title: payload.contactPerson.title,
    firstName: payload.contactPerson.firstName ?? '',
    lastName: payload.contactPerson.lastName ?? '',
    phoneNumber: payload.contactPerson.phoneNumber ?? '',
    nationality: payload.contactPerson.nationality?.shortName ?? '',
    dateOfBirth: payload.contactPerson.dateOfBirth && dob.isValid() ? dob.format('YYYY-MM-DD') : '',
    email: payload.contactPerson.email ?? '',
    politicalExposure: payload.politicalExposure,
    privateAnnualIncome: payload.privateAnnualIncome,
    educationLevel: payload.educationLevel,
    privatePlannedInvestment: payload.privatePlannedInvestment,
    privateNetWorth: payload.privateNetWorth,
    moneyOrigination: payload.moneyOrigination ?? '',
    employmentStatus: payload.employmentStatus,
    employmentEmployer: payload.employmentEmployer,
    country: payload.contactPerson?.address?.country,
    street: payload.contactPerson?.address.street ?? '',
    zipcode: payload.contactPerson?.address.zipcode ?? '',
    city: payload.contactPerson?.address.city ?? '',
    streetNr: payload.contactPerson?.address.streetNr ?? '',
    confirmedInvalid: payload.contactPerson?.address.confirmedInvalid ?? false,
  };
};

const convertFromValuesToPayload = (
  values: PrivateRecordEditFormValues,
): UpdatePrivateKycRecordDto => {
  return {
    contactPerson: {
      title: values.title || null,
      firstName: values.firstName || null,
      lastName: values.lastName || null,
      nationality: (values.nationality as unknown as Country) || null,
      dateOfBirth: values.dateOfBirth ? new Date(values.dateOfBirth).toISOString() : null,
      phoneNumber: values.phoneNumber || null,
      email: values.email || null,
      address: {
        country: (values.country?.shortName as unknown as Country) ?? null,
        street: values.street || null,
        zipcode: values.zipcode || null,
        city: values.city || null,
        streetNr: values.streetNr || null,
        confirmedInvalid: values.confirmedInvalid || false,
      },
    },
    politicalExposure: (values.politicalExposure as any) ?? null,
    privateAnnualIncome: (values.privateAnnualIncome as any) ?? null,
    educationLevel: values.educationLevel ? EducationLevel[values.educationLevel] : null,
    privatePlannedInvestment: (values.privatePlannedInvestment as any) ?? null,
    privateNetWorth: (values.privateNetWorth as any) ?? null,
    moneyOrigination: values.moneyOrigination || null,
    employmentStatus: values.employmentStatus,
    employmentEmployer: values.employmentEmployer ?? null,
  };
};

export const IndividualDetailsPage = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [record, setRecord] = useState<PrivateKycRecord | null>(null);
  const [edit, setEdit] = useState(false);

  const { loading, error } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFineOne(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setRecord(data as PrivateKycRecord);
  }, [id]);

  const updateRecord = useAsyncCallback(async (changes: UpdatePrivateKycRecordDto) => {
    const { data } = await apiClient.api.kycRecordControllerUpdateOnePrivate(id, changes, {
      headers: { 'x-tenant-id': tenantId },
    });

    setRecord(data);
    toast.success('KYC record is updated');
  });

  const handleSubmit = async (
    values: PrivateRecordEditFormValues,
    formikHelpers: FormikHelpers<PrivateRecordEditFormValues>,
  ) => {
    setEdit(false);
    await updateRecord.execute(convertFromValuesToPayload(values));
    formikHelpers.resetForm();
  };

  const initialValues = useMemo(
    () => (record ? convertFromPayloadToValues(record) : ({} as PrivateRecordEditFormValues)),
    [record],
  );

  const formik = useFormik<PrivateRecordEditFormValues>({
    enableReinitialize: true,
    initialValues: initialValues!,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  const handleCancel = () => {
    setEdit(false);
    formik.resetForm();
  };

  if (error) {
    return <div>Record not found</div>;
  } else if (!record || loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Title
        name={`Individual ${record?.contactPerson?.firstName ?? ''} ${
          record?.contactPerson?.lastName ?? ''
        }`}
      />

      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Card>
            <Card.Header title="Quick look" />

            <Card.Body></Card.Body>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <Card.Header title="Onboarding status" />

            <Card.Body></Card.Body>
          </Card>
        </Grid>

        <AdminApprovalForm />

        {record?.id && tenantId && (
          <Grid item xs={12}>
            <KycFileAction kycRecordId={record.id} tenantId={tenantId} />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormikContext.Provider value={formik}>
            <Form>
              <Card>
                <Card.Header title="Individual details" />

                <Card.Body>
                  <PrivateRecordEditForm
                    edit={edit}
                  />
                </Card.Body>

                <Card.Footer sx={{ flexDirection: 'row', justifyContent: 'flex-end', gap: '16px' }}>
                  {edit ? (
                    <>
                      <Button key="cancel" variant="outlined" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button type="submit">Save</Button>
                    </>
                  ) : (
                    <Button onClick={() => setEdit(true)} disabled={updateRecord.loading}>
                      Edit
                    </Button>
                  )}
                </Card.Footer>
              </Card>
            </Form>
          </FormikContext.Provider>
        </Grid>
        <PEPCheckHistoryWidget />
        <OIDIdentificationHistoryWidget />
        <BankVerificationHistoryWidget />
      </Grid>
    </>
  );
};
