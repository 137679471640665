import { useMemo, ReactNode } from 'react';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { Field } from 'formik';
import { countries } from 'countries-list';
import dayjs from 'dayjs';

import { BusinessAnnualIncome, BusinessInvestingPlan, BusinessNetWorth, Country, YesNo } from 'api';
import { Checkbox, Input, Select } from 'components/form';

export interface CompanyRecordEditFormValues {
  id: string;
  tenantId: string;
  companyName: string;
  companyUID: string;
  industry: string;
  legalStructure: string;
  phoneNumber: string;

  politicalExposure: YesNo | null;
  businessPlannedInvestment: BusinessInvestingPlan | null;
  businessAnnualIncome: BusinessAnnualIncome | null;
  businessNetWorth: BusinessNetWorth | null;
  moneyOrigination: string;

  country: Country | undefined;
  street: string;
  zipcode: string;
  city: string;
  streetNr: string;
  confirmedInvalid: boolean;
}

interface Props {
  edit?: boolean;
  editWithId?: boolean;
  addressVerifiedAt?: Date | null | string;
}

export const CompanyRecordEditForm = ({
  edit = true,
  editWithId,
  addressVerifiedAt,
  ...rest
}: Props) => {
  const { countryOptions } = useMemo(() => {
    const countryOptions: Array<{ label: string | ReactNode; value: string }> = [];

    const sortedCountries = Object.entries(countries).sort((a, b) => {
      return a[1].name.localeCompare(b[1].name);
    });

    for (const [key, val] of sortedCountries) {
      countryOptions.push({ value: key, label: `${val.emoji} ${val.name}` });
    }

    return { countryOptions };
  }, []);
  return (
    <Grid container spacing="24px">
      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit || !editWithId}
              id="id"
              name="id"
              label="ID"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="companyUID"
              name="companyUID"
              label="Company UID"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="companyName"
              name="companyName"
              label="Company name"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              label="Phone number"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled
              id="tenantId"
              name="tenantId"
              label="Tenant ID"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="industry"
              name="industry"
              label="Industry"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="legalStructure"
              name="legalStructure"
              label="Legal Structure"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="country"
              name="country.shortName"
              label="Country"
              labelDirection="row"
              component={Select}
              options={countryOptions}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="street"
              name="street"
              label="Street"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="zipcode"
              name="zipcode"
              label="Zip code"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="city"
              name="city"
              label="City"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="streetNr"
              name="streetNr"
              label="Street Nr"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem
            divider
            disableGutters
            sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Field
              disabled={!edit}
              id="confirmedInvalid"
              name="confirmedInvalid"
              label="Confirmed invalid"
              labelDirection="row"
              component={Checkbox}
              color="error"
            />

            {addressVerifiedAt && (
              <Typography variant="caption" fontStyle="italic">
                {dayjs(addressVerifiedAt).format('DD.MM.YYYY, HH:mm')}
              </Typography>
            )}
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};
