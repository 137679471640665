export * from './filter';
export * from './currency';
export * from './pepCheck';
export * from './bank-verification';
export * from './admin-approval';

export type OptionType = {
  label: string;
  value: string;
};

export type OptionsType = OptionType[];

export enum Role {
  KYCSuperAdmin = 'kyc:superadmin',
  KYCViewOnly = 'kyc:viewonly',
}
