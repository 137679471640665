import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import {
  CompaniesPage,
  CompanyDetailsPage,
  IndividualDetailsPage,
  IndividualsPage,
  TenantsPage,
} from './pages';
import { TenantLayout } from './components';

const TenantRouter = () => {
  const { path, url } = useRouteMatch();
  return (
    <TenantLayout>
      <Switch>
        <Route exact path={`${path}/individuals`}>
          <IndividualsPage />
        </Route>
        <Route exact path={`${path}/individuals/:id`}>
          <IndividualDetailsPage />
        </Route>
        <Route exact path={`${path}/companies`}>
          <CompaniesPage />
        </Route>
        <Route exact path={`${path}/companies/:id`}>
          <CompanyDetailsPage />
        </Route>
        <Redirect to={`${url}/individuals`} />
      </Switch>
    </TenantLayout>
  );
};

function App() {
  return (
    <Switch>
      <Route exact path="/tenants">
        <TenantsPage />
      </Route>
      <Route path="/tenants/:tenantId">
        <TenantRouter />
      </Route>
      {/* REDIRECT TO TENANTS PAGE IF THERE IS NO MATCH */}
      <Redirect to="/tenants" />
    </Switch>
  );
}

export default App;
