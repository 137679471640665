import { createTheme } from '@mui/material';

const theme = createTheme({
  spacing: 4,

  palette: {
    primary: {
      main: '#1a4063',
    },
    secondary: {
      main: '#c4c4c4',
    },
    text: {
      primary: '#444444',
      secondary: '#6b6b6b',
    },
  },

  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    h1: {
      // M3/display/large
      fontSize: '57px',
      lineHeight: 1.123, // 64px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h2: {
      // M3/display/medium
      fontSize: '45px',
      lineHeight: 1.156, // 52px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h3: {
      // M3/display/small
      fontSize: '36px',
      lineHeight: 1.223, // 44px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h4: {
      // M3/headline/large
      fontSize: '32px',
      lineHeight: 1.25, // 40px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h5: {
      // M3/headline/medium
      fontSize: '28px',
      lineHeight: 1.286, // 36px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h6: {
      // M3/headline/small
      fontSize: '24px',
      lineHeight: 1.334, // 32px
      fontWeight: 400,
      letterSpacing: 0,
    },

    subtitle1: {
      // M3/title/large
      fontSize: '22px',
      lineHeight: 1.273, // 28px
      fontWeight: 400,
      letterSpacing: 0,
    },

    subtitle2: {
      // M3/title/medium
      fontSize: '16px',
      lineHeight: 1.5, // 24px
      fontWeight: 500,
      letterSpacing: 0.15,
    },

    subtitle3: {
      // M3/title/small
      fontSize: '14px',
      lineHeight: 1.429, // 20px
      fontWeight: 500,
      letterSpacing: 0.1,
    },

    body1: {
      // M3/body/large
      fontSize: '16px',
      lineHeight: 1.5, // 24px
      fontWeight: 400,
      letterSpacing: 0.5,
    },

    body2: {
      // M3/body/medium
      fontSize: '14px',
      lineHeight: 1.429, // 20px
      fontWeight: 400,
      letterSpacing: 0.25,
    },

    caption: {
      // M3/body/small
      fontSize: '12px',
      lineHeight: 1.334, // 16px
      fontWeight: 400,
      letterSpacing: 0.4,
    },

    button: {
      // M3/label/large
      fontSize: '14px',
      lineHeight: 1.429, // 20px
      fontWeight: 500,
      letterSpacing: 0.1,
      textTransform: 'inherit',
    },
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
        color: 'primary',
        size: 'medium',
      },

      styleOverrides: {
        root: {
          textTransform: 'none',
        },

        sizeSmall: {
          padding: '6px 12px',
        },

        outlinedSizeSmall: {
          padding: '5px 11px',
        },

        sizeMedium: {
          padding: '8px 16px',
        },

        outlinedSizeMedium: {
          padding: '7px 15px',
        },

        sizeLarge: {
          padding: '10px 24px',
        },

        outlinedSizeLarge: {
          padding: '9px 23px',
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '7px',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '0',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },

    MuiDialogActions: {
      defaultProps: {
        disableSpacing: true,
      },

      styleOverrides: {
        root: {
          padding: '16px 24px',
          gap: '16px',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          padding: '16px',
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '18px 16px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'inherit',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '220px',
        },
        fullWidth: {
          width: '100%',
        },
        input: {
          '&.MuiInputBase-inputSizeSmall': {
            padding: '7.5px 14px',

            '&.MuiInputBase-inputAdornedEnd': {
              paddingRight: 0,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: '220px',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        input: {
          '&.Mui-disabled': {
            'text-fill-color': 'rgba(0, 0, 0) !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          '&.Mui-disabled': {
            display: 'none',
          },
        },
        outlined: {
          padding: '7.5px 14px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        popper: {
          '&&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
            marginBottom: '4px',
          },
          '&&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
            marginTop: '4px',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: 1,
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& > .MuiInputBase-root': {
            width: 'auto',
          },
        },
      },
    },
  },
});

export default theme;
