import { KycRecord } from 'api';
import { Table, TableFilterChangeFn, TableInitialFilters, TableQuickFilters } from 'components';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';

type PrivateKYCRecordType = { [key: string]: any } & KycRecord;

interface IndividualsTableProps {
  data: KycRecord[];
  onFiltersChange: TableFilterChangeFn;
  initialFilters: TableInitialFilters;
  quickFilters?: TableQuickFilters;
  pageSize: number;
  pageIndex: number;
  count: number;
}

export function IndividualsTable(props: IndividualsTableProps) {
  const history = useHistory();
  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      Filter: undefined,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: function Cell({ row: { original } }: CellProps<PrivateKYCRecordType>) {
        return (
          <span>{`${original.contactPerson.firstName ?? ''} ${
            original.contactPerson.lastName ?? ''
          }`}</span>
        );
      },
      Filter: undefined,
    },
    {
      Header: 'E-mail',
      accessor: 'contactPerson.email',
      Filter: undefined,
    },
  ];

  return (
    <Table<PrivateKYCRecordType>
      name="PrivateKYCRecord Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.data}
      columns={columns}
      count={props.count}
      onClick={(row) =>
        history.push(`/tenants/${row.original.tenantId}/individuals/${row.original.id}`)
      }
    />
  );
}
