import { memo, ComponentType, useState } from 'react';
import { FieldProps } from 'formik';
import { InputLabel, styled } from '@mui/material';

import { shallowEqual } from 'utils';

type LabelDirection = 'row' | 'column';

type Props = FieldProps & {
  label?: string;
  labelDirection?: LabelDirection;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
} & Record<string, any>;

const Container = styled('div')<{ direction: LabelDirection }>`
  display: flex;

  ${(props) => {
    switch (props.direction) {
      case 'column':
        return `
          flex-direction: column;
        `;
      case 'row':
        return `
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `;
      default:
        return '';
    }
  }}
`;

export function withFormLabel(FormComponent: ComponentType<FieldProps & any>) {
  return memo(
    function WrappedWithFormComponent(props: Props) {
      const {
        label,
        labelDirection = 'column',
        error = false,
        field,
        disabled,
        ...restProps
      } = props;

      const [focused, setFocused] = useState(false);

      const decorated = { ...field };

      const onFocus = (event?: unknown) => {
        if (!event) return;

        setFocused(true);
        if (restProps?.onFocus) restProps?.onFocus(event);
      };

      decorated.onBlur = (event?: unknown) => {
        if (!event) return;

        setFocused(false);
        if (field?.onBlur) field?.onBlur(event);
      };

      return (
        <Container direction={labelDirection}>
          {!!label && (
            <InputLabel
              required={props.required}
              error={error}
              focused={focused}
              htmlFor={field.name}
              disabled={disabled}
            >
              {label}
            </InputLabel>
          )}
          {/* @ts-ignore */}
          <FormComponent
            field={decorated}
            // For some components overrides above are just enough,
            // but some components are ignoring focus unless specified as here:
            onFocus={onFocus}
            onBlur={decorated.onBlur}
            error={error}
            disabled={disabled}
            {...restProps}
          />
        </Container>
      );
    },
    (prev, next) => {
      const { field: pField, form: pForm, ...pRest } = prev;
      const { field: nField, form: nForm, ...nRest } = next;

      return shallowEqual(pRest, nRest) && shallowEqual(pField, nField);
    },
  );
}
