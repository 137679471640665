import { OptionsType } from 'types';
export * from './validations';
export * from './format';
export * from './trim-object';
export * from './query-string';
export * from './move-decimal-point';
export * from './get-number';
export * from './select-options';
export * from './shallow-equal';

export const downloadFile = (data: Blob | string, fileName: string) => {
  let blob: Blob;

  if (data instanceof Blob) {
    blob = data;
  } else {
    blob = new Blob([data], { type: 'octet/stream' });
  }

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};
export const openFileInBrowser = (data: Blob | string, fileName: string) => {
  const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';

  // Check if the file extension is supported for opening in the browser
  if (fileExtension in viewableExtensions) {
    let blob: Blob;
    if (data instanceof Blob) {
      blob = data;
    } else {
      blob = new Blob([data], { type: viewableExtensions[fileExtension] });
    }
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
    // window.URL.revokeObjectURL(url);
  } else {
    downloadFile(data, fileName);
  }
};

export const viewableExtensions: Record<string, string> = {
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  txt: 'text/plain',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  mp3: 'audio/mpeg',
  wav: 'audio/wav',
  ogg: 'audio/ogg',
  aac: 'audio/aac',
  mp4: 'video/mp4',
  webm: 'video/webm',
  html: 'text/html',
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const waitUntil = async (condition: boolean, ms: number) => {
  return await new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (condition) {
        resolve();
        clearInterval(interval);
      }
    }, ms);
  });
};

export const renderMutipleFilterValue = (options: OptionsType) => (value: string[]) => {
  if (value?.length === 1) return options.find((opt) => opt.value === value[0])?.label ?? value;
  if (value?.length > 1) return '+';
  return 'All';
};

export const omitEmptyFilters = (value?: string | number | string[]) =>
  value === undefined ||
  value === -1 ||
  value === '' ||
  (Array.isArray(value) && value.length === 0);

export const omitBy = (
  obj: Record<string, string | number | string[]>,
  fn: (arg0: string | number | string[]) => boolean,
) => {
  return Object.keys(obj)
    .filter((k) => !fn(obj[k]))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key],
      }),
      {} as Record<string, string | number | string[]>,
    );
};
