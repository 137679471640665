import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { BankVerificationHistory, DepositDataDto, apiClient } from 'api';
import { Card } from 'components';
import { Field, Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { Select } from './form/Select';
import dayjs from 'dayjs';
import { BankVerificationStatus } from 'types';

export const getBankVerificationStatusOptions = () => {
  return Object.values(BankVerificationStatus).map((val) => ({
    label: val,
    value: val,
  }));
};

export const BankVerificationHistoryWidget = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [history, setHistory] = useState<BankVerificationHistory[] | null>(null);
  const [paymentsData, setPaymentsData] = useState<DepositDataDto | null>(null);
  const bankVerificationStatusOptions = useMemo(() => getBankVerificationStatusOptions(), []);

  const { loading, execute } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindAllBankVerification(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setHistory(data as BankVerificationHistory[]);
  }, [id]);

  const { loading: paymentsDataLoading } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindDepositData(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setPaymentsData(data as DepositDataDto);
  }, [id]);

  const updateStatus = useAsyncCallback(async ({ status }: { status?: BankVerificationStatus }) => {
    if (status) {
      await apiClient.api.kycRecordControllerCreateBankVerification(
        id,
        { status },
        {
          headers: { 'x-tenant-id': tenantId },
        },
      );
      await execute();
    }
  });

  return (
    <Grid item xs={12}>
      <Card>
        <Card.Header title="Bank account verification" />
        <Card.Body
          loadingContent={loading && paymentsDataLoading}
          wrapperSx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              status: history?.[0]?.status as BankVerificationStatus,
            }}
            onSubmit={updateStatus.execute}
          >
            <Form>
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap={4}>
                <Field
                  id="status"
                  name="status"
                  label="Status"
                  placeholder="Please select"
                  component={Select}
                  options={bankVerificationStatusOptions}
                />
                <Button type="submit">Save</Button>
              </Box>
            </Form>
          </Formik>
          {paymentsData && (
            <Box>
              <Typography variant="subtitle2">Payments Data</Typography>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>IBAN</TableCell>
                      <TableCell>{paymentsData.iban}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Account holder</TableCell>
                      <TableCell>{paymentsData.accountHolder}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Address</TableCell>
                      <TableCell>{paymentsData.address}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {!!history?.length && (
            <Box>
              <Typography variant="subtitle2">Change history</Typography>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableCell>Change</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Modified by</TableCell>
                  </TableHead>
                  <TableBody>
                    {history?.map((h) => (
                      <TableRow>
                        <TableCell>{h.status}</TableCell>
                        <TableCell>{dayjs(h.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{h.createdBy}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Card.Body>
      </Card>
    </Grid>
  );
};
