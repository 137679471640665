import { useMemo, useState } from 'react';
import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { CandidateDto, PEPCheckHistory, PEPCheckStatus, apiClient } from 'api';
import { Card, FormDialog } from 'components';
import { getPEPCheckStatusOptions } from 'utils';
import { Select } from './form/Select';

export const PEPCheckHistoryWidget = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [history, setHistory] = useState<PEPCheckHistory[] | null>(null);
  const [candidates, setCandidates] = useState<CandidateDto[] | null>(null);

  const pepCheckStatusOptions = useMemo(() => getPEPCheckStatusOptions(), []);

  const { loading, execute } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindAllPepChecks(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setHistory(data as PEPCheckHistory[]);
  }, [id]);

  const requestCheck = useAsyncCallback(async (e?: any, identifier?: string) => {
    const { data } = await apiClient.api.kycRecordControllerRequestPepCheck(
      id,
      {
        identifier,
      },
      {
        headers: { 'x-tenant-id': tenantId },
      },
    );
    if (data?.candidates?.length) {
      setCandidates(data?.candidates);
      return;
    }
    toast.success('CRIF report ordered successfully. Full report will be available in GDrive soon');
    await execute();
  });

  const updateStatus = useAsyncCallback(async ({ status }: { status?: PEPCheckStatus }) => {
    if (status) {
      await apiClient.api.kycRecordControllerCreatePepCheck(
        id,
        { status },
        {
          headers: { 'x-tenant-id': tenantId },
        },
      );
      await execute();
    }
  });

  const lastCrifCheck = useMemo(() => history?.find((h) => h.crifCheck), [history]);

  return (
    <Grid item xs={12}>
      <Card>
        <Card.Header title="PEP & Sanctions list checks" />
        <Card.Body
          loadingContent={loading}
          wrapperSx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Box display="flex" flexDirection="row" gap={4}>
            <Button
              type="button"
              onClick={requestCheck.execute}
              endIcon={
                requestCheck.loading ? (
                  <CircularProgress size="1em" sx={{ color: '#fff' }} />
                ) : undefined
              }
            >
              Request check from CRIF
            </Button>
            {!!lastCrifCheck && (
              <Typography variant="caption">
                <Box>Last check: {dayjs(lastCrifCheck.created).format('DD.MM.YYYY')}</Box>
                <Box>
                  {dayjs(lastCrifCheck.created).format('HH:mm')} - {lastCrifCheck.adminName}
                </Box>
              </Typography>
            )}
          </Box>
          <Formik
            enableReinitialize
            initialValues={{
              status: history?.[0]?.status as PEPCheckStatus,
            }}
            onSubmit={updateStatus.execute}
          >
            <Form>
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap={4}>
                <Field
                  id="status"
                  name="status"
                  label="Status"
                  placeholder="Please select"
                  component={Select}
                  options={pepCheckStatusOptions}
                />
                <Button type="submit">Save</Button>
              </Box>
            </Form>
          </Formik>
          {!!history?.length && (
            <Box>
              <Typography variant="subtitle2">Change history</Typography>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableCell>Change</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Modified by</TableCell>
                  </TableHead>
                  <TableBody>
                    {history?.map((h) => (
                      <TableRow>
                        <TableCell>
                          {h.fileStored
                            ? 'CRIF report saved to documents'
                            : h.status?.replaceAll('_', ' ')}
                        </TableCell>
                        <TableCell>{dayjs(h.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{h.adminName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Card.Body>
      </Card>
      <FormDialog
        size="sm"
        header="Determine person to check"
        open={!!candidates?.length}
        onClose={() => setCandidates(null)}
        initialValues={{
          identifier: '',
        }}
        onSubmit={async (values) => {
          try {
            await requestCheck.execute(null, values.identifier);
            setCandidates(null);
          } catch (e) {
            console.log('Error: ', e);
          }
        }}
      >
        <Field
          id="identifier"
          name="identifier"
          label="For which person should CRIF be determined?"
          placeholder="Please select"
          component={Select}
          options={
            candidates?.map((c) => ({
              label: `${c.firstName} ${c.lastName}, ${c.birthDate}, ${c.address?.street} ${c.address?.houseNumber} ${c.address?.city} ${c.address?.country}`,
              value: c.identifier,
            })) ?? []
          }
        />
      </FormDialog>
    </Grid>
  );
};
