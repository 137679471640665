import {
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from '@mui/material';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { AdminApprovalHistory, apiClient } from 'api';
import { AdminApprovalStatus } from 'types';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { Select } from 'components/form/Select';
import { Card } from 'components';

export const getAdminApprovalStatusOptions = () => {
  return Object.values(AdminApprovalStatus).map((val) => ({
    label: val,
    value: val,
  }));
};

export const AdminApprovalForm = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [history, setHistory] = useState<AdminApprovalHistory[] | null>(null);
  const adminApprovalStatusOptions = useMemo(() => getAdminApprovalStatusOptions(), []);

  const { loading, execute } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindAllAdminApproval(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setHistory(data as AdminApprovalHistory[]);
  }, [id]);

  const updateStatus = useAsyncCallback(async ({ status }: { status?: AdminApprovalStatus }) => {
    if (status) {
      await apiClient.api.kycRecordControllerCreateAdminApproval(
        id,
        { status },
        {
          headers: { 'x-tenant-id': tenantId },
        },
      );
      await execute();
    }
  });

  return (
    <Grid item xs={4}>
      <Card>
        <Card.Header title="Admin approval status" />
        <Card.Body
          loadingContent={loading}
          wrapperSx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Grid container spacing="10px" gap={6}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap={4}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    status: history?.[0]?.status as AdminApprovalStatus,
                  }}
                  onSubmit={updateStatus.execute}
                >
                  <Form>
                    <Box display="flex" flexDirection="row" alignItems="flex-end" gap={4}>
                      <Field
                        id="status"
                        name="status"
                        label="Status"
                        placeholder="Please select"
                        component={Select}
                        options={adminApprovalStatusOptions}
                      />
                      <Button type="submit">Save</Button>
                    </Box>
                  </Form>
                </Formik>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Modified by</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {history?.map((h) => (
                      <TableRow>
                        <TableCell>{h.status}</TableCell>
                        <TableCell>{dayjs(h.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{h.createdBy}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Card.Body>
      </Card>
    </Grid>
  );
};
