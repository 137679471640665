import { useMemo, ReactNode } from 'react';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { Field } from 'formik';
import { countries, getEmojiFlag } from 'countries-list';

import nationalities from 'i18n-nationality';

import dayjs from 'dayjs';

import {
  Country,
  Title,
  YesNo,
  PrivateAnnualIncome,
  PrivateInvestingPlan,
  PrivateNetWorth,
  EducationLevel,
  EmploymentStatus,
} from 'api';
import { Checkbox, Input, Select } from 'components/form';
import {
  getYesNoOptions,
  getPrivateAnnualIncomeOptions,
  getPrivateInvestingPlanOptions,
  getPrivateNetWorthOptions,
  getEmploymentStatusOptions,
  getTitleOptions,
} from 'utils';

export interface PrivateRecordEditFormValues {
  id: string;
  tenantId: string;
  title?: Title | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  nationality: string;
  dateOfBirth: string;
  email: string;
  politicalExposure: YesNo | null;
  privateAnnualIncome: PrivateAnnualIncome | null;
  educationLevel: EducationLevel | null;
  privatePlannedInvestment: PrivateInvestingPlan | null;
  privateNetWorth: PrivateNetWorth | null;
  moneyOrigination: string;
  employmentStatus: EmploymentStatus | null;
  employmentEmployer: string | null;

  country: Country | null;
  street: string;
  zipcode: string;
  city: string;
  streetNr: string;
  confirmedInvalid: boolean;
}

interface Props {
  edit?: boolean;
  editWithId?: boolean;
  addressVerifiedAt?: Date | null | string;
}
nationalities.registerLocale(require('i18n-nationality/langs/en.json'));

export const PrivateRecordEditForm = ({ edit = true, editWithId, addressVerifiedAt }: Props) => {
  const {
    countryOptions,
    nationalityOptions,
    yesNoOptions,
    privateAnnualIncomeOptions,
    privateInvestingPlanOptions,
    privateNetWorthOptions,
    titleOptions,
  } = useMemo(() => {
    const countryOptions: Array<{ label: string | ReactNode; value: string }> = [];
    const nationalityOptions: Array<{ label: string | ReactNode; value: string }> = [];
    const nationalitiesS = nationalities.getNames('en');
    const sortedCountries = Object.entries(countries).sort((a, b) => {
      return a[1].name.localeCompare(b[1].name);
    });

    for (const [key, val] of sortedCountries) {
      countryOptions.push({ value: key, label: `${val.emoji} ${val.name}` });
    }

    Object.entries(nationalitiesS).forEach(([key, val]) => {
      nationalityOptions.push({
        value: key,
        label: `${getEmojiFlag(key)} ${val}`,
      });
    });

    return {
      countryOptions,
      nationalityOptions,
      yesNoOptions: getYesNoOptions(),
      privateAnnualIncomeOptions: getPrivateAnnualIncomeOptions(),
      privateInvestingPlanOptions: getPrivateInvestingPlanOptions(),
      privateNetWorthOptions: getPrivateNetWorthOptions(),
      titleOptions: getTitleOptions(),
    };
  }, []);

  return (
    <Grid container spacing="24px">
      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit || !editWithId}
              required={editWithId}
              id="id"
              name="id"
              label="ID"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="title"
              name="title"
              label="Title"
              labelDirection="row"
              component={Select}
              options={titleOptions}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="firstName"
              name="firstName"
              label="First name"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="lastName"
              name="lastName"
              label="Last name"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="nationality"
              name="nationality"
              label="Nationality"
              labelDirection="row"
              component={Select}
              options={nationalityOptions}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled
              id="tenantId"
              name="tenantId"
              label="Tenant ID"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              type="email"
              id="email"
              name="email"
              label="Email"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              label="Phone number"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              label="Date of birth"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="privateNetWorth"
              name="privateNetWorth"
              label="Net worth"
              labelDirection="row"
              component={Select}
              options={privateNetWorthOptions}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="privatePlannedInvestment"
              name="privatePlannedInvestment"
              label="Planned investment"
              labelDirection="row"
              component={Select}
              options={privateInvestingPlanOptions}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="politicalExposure"
              name="politicalExposure"
              label="Political exposure"
              labelDirection="row"
              component={Select}
              options={yesNoOptions}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="privateAnnualIncome"
              name="privateAnnualIncome"
              label="Annual income"
              labelDirection="row"
              component={Select}
              options={privateAnnualIncomeOptions}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="moneyOrigination"
              name="moneyOrigination"
              label="Money origination"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="country"
              name="country.shortName"
              label="Country"
              labelDirection="row"
              component={Select}
              options={countryOptions}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="street"
              name="street"
              label="Street"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="zipcode"
              name="zipcode"
              label="Zip code"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="city"
              name="city"
              label="City"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="streetNr"
              name="streetNr"
              label="Street Nr"
              labelDirection="row"
              component={Input}
            />
          </ListItem>

          <ListItem
            divider
            disableGutters
            sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <Field
              disabled={!edit}
              id="confirmedInvalid"
              name="confirmedInvalid"
              label="Confirmed invalid"
              labelDirection="row"
              component={Checkbox}
              color="error"
            />

            {addressVerifiedAt && (
              <Typography variant="caption" fontStyle="italic">
                {dayjs(addressVerifiedAt).format('DD.MM.YYYY, HH:mm')}
              </Typography>
            )}
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={6}>
        <List>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="employmentStatus"
              name="employmentStatus"
              label="Employment status"
              labelDirection="row"
              component={Select}
              options={getEmploymentStatusOptions()}
            />
          </ListItem>
          <ListItem divider disableGutters>
            <Field
              disabled={!edit}
              id="employmentEmployer"
              name="employmentEmployer"
              label="Employer"
              labelDirection="row"
              component={Input}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};
