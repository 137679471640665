import React, { useCallback } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useParams } from 'react-router';
import { isEmpty } from 'ramda';

import { Card, TableFilterChangeData } from 'components';
import { useUrlQuery } from 'hooks';
import { apiClient, CreateBusinessKycRecordDto, KycRecordControllerFindAllParams } from 'api';
import { CompaniesTable } from './CompaniesTable';
import { NewBusinessRecordWidget } from './NewBusinessRecordWidget';
import { getNumber } from 'utils/get-number';
import { KycFilters } from '../KycFilters';
import { useAsyncDebounce } from 'react-table';

export const CompaniesPage = () => {
  const { pushQuery, replaceQuery, parsedQuery, filters } =
    useUrlQuery<KycRecordControllerFindAllParams>();
  const { tenantId } = useParams<{ tenantId: string }>();

  const pageSize = getNumber(parsedQuery.pageSize, 50);
  const pageIndex = getNumber(parsedQuery.pageIndex, 0);

  const { result, execute } = useAsyncCallback(async (query: KycRecordControllerFindAllParams) => {
    const { data } = await apiClient.api.kycRecordControllerFindAll(
      {
        ...query,
        type: 'BusinessKycRecord',
      },
      { headers: { 'x-tenant-id': tenantId } },
    );
    return data;
  });

  const createRecord = useAsyncCallback(async (record: CreateBusinessKycRecordDto) => {
    await apiClient.api.kycRecordControllerCreateOneBusiness(record, {
      headers: { 'x-tenant-id': tenantId },
    });
    await execute(parsedQuery);
  });

  const handleFiltersChange = async (data: TableFilterChangeData) => {
    pushQuery({
      ...(data.filters ?? filters),
      pageSize: data.pageSize ?? pageSize,
      pageIndex: data.pageIndex ?? pageIndex,
    });
  };

  useDeepCompareEffect(() => {
    if (isEmpty(parsedQuery)) {
      replaceQuery({ pageSize, pageIndex });
    } else {
      execute(parsedQuery);
    }
    // eslint-disable-next-line
  }, [parsedQuery]);
  const onFiltersChangeDebounced = useAsyncDebounce(handleFiltersChange, 500);
  const handleFiltersClear = useCallback(() => {
    pushQuery({ pageSize: 50, pageIndex: 0 });
  }, [pushQuery]);

  return (
    <Card>
      <Card.Header title="Companies">
        <NewBusinessRecordWidget onSubmit={(values) => createRecord.execute(values)} />
      </Card.Header>
      <KycFilters
        initialFilters={filters}
        onChange={onFiltersChangeDebounced}
        onClearFilters={handleFiltersClear}
      />
      <Card.Body>
        <CompaniesTable
          data={result?.items ?? []}
          onFiltersChange={handleFiltersChange}
          count={result?.meta?.totalItems ?? 0}
          initialFilters={filters}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      </Card.Body>
    </Card>
  );
};
