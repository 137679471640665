import { useState } from 'react';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Country, CreatePrivateKycRecordDto } from 'api';
import { FormDialog } from 'components';
import { PrivateRecordEditForm, PrivateRecordEditFormValues } from 'components/form-parts';

const convertFromValuesToPayload = (
  values: PrivateRecordEditFormValues,
): CreatePrivateKycRecordDto => {
  return {
    contactPerson: {
      firstName: values.firstName || null,
      lastName: values.lastName || null,
      nationality: (values.nationality as unknown as Country) || null,
      dateOfBirth: values.dateOfBirth ? new Date(values.dateOfBirth).toISOString() : null,
      phoneNumber: values.phoneNumber || null,
      address: {
        country: (values.country?.shortName as unknown as Country) ?? null,
        street: values.street || null,
        zipcode: values.zipcode || null,
        city: values.city || null,
        streetNr: values.streetNr || null,
        confirmedInvalid: values.confirmedInvalid || false,
      },
    },
    id: values.id,
    email: values.email || null,
    politicalExposure: (values.politicalExposure as any) ?? null,
    privateAnnualIncome: (values.privateAnnualIncome as any) ?? null,
    educationLevel: values.educationLevel || null,
    privatePlannedInvestment: (values.privatePlannedInvestment as any) ?? null,
    privateNetWorth: (values.privateNetWorth as any) ?? null,
    moneyOrigination: values.moneyOrigination || null,

  };
};

type Props = {
  onSubmit: (arg0: CreatePrivateKycRecordDto) => Promise<void>;
};

export const NewPrivateRecordWidget = ({ onSubmit }: Props) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Add a new individual</Button>
      <FormDialog<PrivateRecordEditFormValues>
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        header="Add a new individual"
        initialValues={{ tenantId: tenantId } as PrivateRecordEditFormValues}
        onSubmit={async (values) => {
          try {
            await onSubmit(convertFromValuesToPayload(values));
            setModalOpen(false);
          } catch (e) {
            console.log('Error: ', e);
          }
        }}
      >
        <PrivateRecordEditForm editWithId />
      </FormDialog>
    </>
  );
};
