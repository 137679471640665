import { PropsWithChildren } from 'react';
import { Paper, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { Loader } from 'components';

interface Props {
  loadingContent?: boolean;
  sx?: SxProps<Theme>;
  wrapperSx?: SxProps<Theme>;
  className?: string;
  wrapperClassName?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    flexWrap: 'unset',
  },
  wrapper: {
    '&.loading': {
      opacity: 0.45,
    },
  },
  overlap: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
}));

export const CardBody = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const { loadingContent, children } = props;

  return (
    <Paper elevation={0} className={clsx(classes.container, props.className)} sx={props.sx}>
      {loadingContent && (
        <Box className={classes.overlap}>
          <Loader />
        </Box>
      )}

      <Box
        className={clsx(classes.wrapper, props.wrapperClassName, {
          loading: loadingContent,
        })}
        sx={props.wrapperSx}
      >
        {children}
      </Box>
    </Paper>
  );
};
