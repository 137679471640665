import React, { ChangeEvent, useMemo } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { omitBy, omitEmptyFilters, renderMutipleFilterValue } from 'utils';
import { Input } from '../components/form';

interface Props {
  initialFilters: Record<string, any>;
  onChange: (data: { filters: Record<string, string | number | string[]> }) => void;
  onClearFilters: () => void;
}

export const KycFilters = ({ initialFilters, onChange, onClearFilters }: Props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        generalFindString: '',
        ...initialFilters,
      }}
      onSubmit={(values) => {
        onChange({
          filters: omitBy(values, omitEmptyFilters),
        });
      }}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                id="generalFindString"
                name="generalFindString"
                type="text"
                label="General Find"
                labelDirection="column"
                component={Input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChange({
                    filters: omitBy(
                      {
                        ...values,
                        generalFindString: e.target.value,
                      },
                      omitEmptyFilters,
                    ),
                  });
                }}
                placeholder="Search by any value"
                className="filter"
                labelClassName="filter"
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
