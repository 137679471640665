import { ChangeEvent, useCallback } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { FieldProps } from 'formik';

import { withFormLabel } from './withFormLabel';

type Props = FieldProps & CheckboxProps;

export const Checkbox = withFormLabel(({ field, ...props }: Props): JSX.Element => {
  const checked = field?.value ?? field?.checked ?? props.checked;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (field?.onChange) field?.onChange(e);
      if (props.onChange) props.onChange(e, checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field?.onChange, props.onChange],
  );

  return <MuiCheckbox {...field} {...props} onChange={onChange} checked={checked} />;
});
