import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { Api } from './client';
import { baseUrl } from 'wrappers/ConfigLoaderWrapper';
import { stringifyQuery } from 'utils';
import { Api as DocumentApi } from '../document/client';

const getRejected = (error: AxiosError) => {
  const rawErrorMessage = (error.response?.data as any)?.detail;
  const errorMessage =
    typeof rawErrorMessage === 'string'
      ? rawErrorMessage
      : Array.isArray(rawErrorMessage)
      ? rawErrorMessage.join(';\n')
      : `Unknown error${error.code ? ` (${error.code})` : ''}`;

  toast.error(errorMessage);
  throw error;
};

const apiClient = new Api({
  baseURL: baseUrl,
  paramsSerializer: stringifyQuery,
  securityWorker: (securityData: { token: string } | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: `Bearer ${securityData.token}`,
        },
      };
    }
  },
});
apiClient.instance.interceptors.response.use(undefined, getRejected);

function createDocumentsClient() {
  const documentApiClient = new DocumentApi({
    baseURL: window.appConfig.documentsBaseUrl,
    paramsSerializer: stringifyQuery,
    securityWorker: (securityData: { token: string } | null) => {
      if (securityData) {
        return {
          headers: {
            Authorization: `Bearer ${securityData.token}`,
          },
        };
      }
    },
  });
  documentApiClient.instance.interceptors.response.use(undefined, getRejected);
  window.documentApiClient = documentApiClient;
}

export { apiClient, createDocumentsClient };

export * from './client';
