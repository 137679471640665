import { useMemo, useState } from 'react';
import {
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { OIDIdentificationHistory, OIDIdentificationStatus, apiClient } from 'api';
import { Card, FormDialog } from 'components';
import { Select } from './form/Select';
import { getOIDIdentificationStatusOptions } from 'utils';

export const OIDIdentificationHistoryWidget = () => {
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const [history, setHistory] = useState<OIDIdentificationHistory[] | null>(null);
  const [checks, setChecks] = useState<object | undefined>();

  const oidIdentificationStatusOptions = useMemo(() => getOIDIdentificationStatusOptions(), []);

  const { loading, execute } = useAsync(async () => {
    const { data } = await apiClient.api.kycRecordControllerFindOidIdentificationHistory(id, {
      headers: { 'x-tenant-id': tenantId },
    });

    setHistory(data);
  }, [id]);

  const requestIdentification = useAsyncCallback(async () => {
    await apiClient.api.kycRecordControllerRequestOidIdentification(id, {
      headers: { 'x-tenant-id': tenantId },
    });
    toast.success('OID identification process has started');
    await execute();
  });

  const getAccessLink = useAsyncCallback(async () => {
    const { data } = await apiClient.api.kycRecordControllerGetAccessLinkToOidIdentification(id, {
      headers: { 'x-tenant-id': tenantId },
    });
    navigator.clipboard.writeText(data);
    toast.success('Link has been copied to clipboard');
  });

  const updateStatus = useAsyncCallback(
    async ({ status }: { status?: OIDIdentificationStatus }) => {
      if (status) {
        await apiClient.api.kycRecordControllerCreateOidIdentificationHistory(
          id,
          { status },
          {
            headers: { 'x-tenant-id': tenantId },
          },
        );
        await execute();
      }
    },
  );

  const lastStart = useMemo(() => {
    if (history?.length) {
      const token = history[0].token;
      const historyToReverse = [...history];
      if (token) {
        return historyToReverse.reverse().find((h) => h.token === token);
      }
    }
  }, [history]);

  return (
    <Grid item xs={12}>
      <Card>
        <Card.Header title="Swisscom OID Identification" />
        <Card.Body
          loadingContent={loading}
          wrapperSx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Box display="flex" flexDirection="row" gap={4}>
            {history?.[0]?.token && (
              <Button
                type="button"
                onClick={getAccessLink.execute}
                endIcon={
                  getAccessLink.loading ? (
                    <CircularProgress size="1em" sx={{ color: '#fff' }} />
                  ) : undefined
                }
              >
                Get access link
              </Button>
            )}
            <Button
              type="button"
              onClick={requestIdentification.execute}
              endIcon={
                requestIdentification.loading ? (
                  <CircularProgress size="1em" sx={{ color: '#fff' }} />
                ) : undefined
              }
            >
              Start OID identification
            </Button>
            {!!lastStart && (
              <Typography variant="caption">
                <Box>OID started at: {dayjs(lastStart.created).format('DD.MM.YYYY')}</Box>
                <Box>
                  {dayjs(lastStart.created).format('HH:mm')} - {lastStart.adminName}
                </Box>
              </Typography>
            )}
          </Box>
          <Formik
            enableReinitialize
            initialValues={{
              status: history?.[0]?.status as OIDIdentificationStatus,
            }}
            onSubmit={updateStatus.execute}
          >
            <Form>
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap={4}>
                <Field
                  id="status"
                  name="status"
                  label="Status"
                  placeholder="Please select"
                  component={Select}
                  options={oidIdentificationStatusOptions}
                />
                <Button type="submit">Save</Button>
              </Box>
            </Form>
          </Formik>
          {!!history?.length && (
            <Box>
              <Typography variant="subtitle2">Change history</Typography>
              <TableContainer sx={{ maxHeight: '500px' }}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableCell>Change</TableCell>
                    <TableCell>Created at</TableCell>
                    <TableCell>Modified at</TableCell>
                    <TableCell>Modified by</TableCell>
                    <TableCell>Checks</TableCell>
                  </TableHead>
                  <TableBody>
                    {history?.map((h) => (
                      <TableRow>
                        <TableCell>{h.status}</TableCell>
                        <TableCell>{dayjs(h.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{dayjs(h.updatedAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{h.adminName}</TableCell>
                        <TableCell>
                          {!!h.checks && (
                            <Button
                              type="button"
                              variant="outlined"
                              onClick={() => setChecks(h.checks)}
                            >
                              Show details
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Card.Body>
      </Card>
      <FormDialog
        size="sm"
        header="Checks"
        open={!!checks}
        initialValues={{}}
        onClose={() => setChecks(undefined)}
        onSubmit={() => setChecks(undefined)}
        confirmBtnName="Close"
      >
        {Array.isArray(checks) ? (
          (checks as any)?.map((c: object) => <pre>{JSON.stringify(c, null, 4)}</pre>)
        ) : (
          <pre>{JSON.stringify(checks, null, 4)}</pre>
        )}
      </FormDialog>
    </Grid>
  );
};
