import { useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdClose } from 'react-icons/md';

const useInputStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

type GroupName = string;

interface GroupInputContainerProps {
  value: GroupName;
  onChange?: (value: GroupName) => void;
  onDelete?: (value: GroupName) => void;
}

const GroupInputContainer = (props: GroupInputContainerProps) => {
  const classes = useInputStyles();
  const [name, setName] = useState(props.value);

  useEffect(() => {
    setName(props.value);
  }, [props.value]);

  const handleChange = () => {
    if (props.value !== name) {
      props.onChange?.(name);
    }
  };

  return (
    <Box className={classes.container}>
      <TextField
        required
        fullWidth
        size="small"
        variant="outlined"
        type="text"
        placeholder="Name"
        value={name}
        onChange={({ target }) => setName(target.value)}
        onBlur={handleChange}
      />
      <IconButton size="small" onClick={() => props.onDelete?.(name)}>
        <MdClose />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: '250px',
    overflow: 'auto',
  },
  label: {
    fontSize: '14px',
  },
}));

interface Props {
  label: string;
  groups: GroupName[];
  onChange?: (tags: GroupName[]) => void;
}

export const GroupSelect = (props: Props) => {
  const classes = useStyles();
  const [withPlaceholder, setWithPlaceholder] = useState(
    props.groups.length === 0,
  );

  const isAddBtnDisabled = useMemo(() => {
    return withPlaceholder || props.groups.some((group) => group.trim() === '');
  }, [withPlaceholder, props.groups]);

  const handleAdd = () => {
    setWithPlaceholder(true);
  };

  const handleChange = (index: number, value: GroupName) => {
    const res = [...props.groups];
    res[index] = value;
    props.onChange?.(res);
  };

  const handleDelete = (index: number, value: GroupName) => {
    const res = [...props.groups];
    res.splice(index, 1);
    props.onChange?.(res);
  };

  return (
    <Box>
      <InputLabel classes={{ root: classes.label }}>{props.label}</InputLabel>
      <Box className={classes.container}>
        {props.groups.map((tag, index) => (
          <GroupInputContainer
            key={index}
            value={tag}
            onChange={(v) => handleChange(index, v)}
            onDelete={(v) => handleDelete(index, v)}
          />
        ))}

        {withPlaceholder && (
          <GroupInputContainer
            value={''}
            onChange={(v) => {
              props.onChange?.([...props.groups, v]);
              setWithPlaceholder(false);
            }}
            onDelete={(v) => {
              setWithPlaceholder(false);
            }}
          />
        )}

        <Button
          variant="contained"
          onClick={() => handleAdd()}
          disabled={isAddBtnDisabled}
        >
          Add new group
        </Button>
      </Box>
    </Box>
  );
};
