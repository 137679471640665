import { useState } from 'react';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Country, CreateBusinessKycRecordDto } from 'api';
import { FormDialog } from 'components';
import { CompanyRecordEditForm, CompanyRecordEditFormValues } from 'components/form-parts';

const convertFromValuesToPayload = (
  values: CompanyRecordEditFormValues,
): CreateBusinessKycRecordDto => {
  return {
    id: values.id,
    companyName: values.companyName || null,
    companyUID: values.companyUID || null,
    industry: values.industry || null,
    legalStructure: values.legalStructure || null,
    contactPerson: {
      phoneNumber: values.phoneNumber || null,
    },
    politicalExposure: values.politicalExposure || null,
    businessPlannedInvestment: values.businessPlannedInvestment || null,
    businessAnnualIncome: values.businessAnnualIncome || null,
    businessNetWorth: values.businessNetWorth || null,
    moneyOrigination: values.moneyOrigination || null,

    address: {
      country: (values.country?.shortName as unknown as Country) ?? null,
      street: values.street || null,
      zipcode: values.zipcode || null,
      city: values.city || null,
      streetNr: values.streetNr || null,
      confirmedInvalid: values.confirmedInvalid || false,
    },
  };
};

type Props = {
  onSubmit: (arg0: CreateBusinessKycRecordDto) => Promise<void>;
};

export const NewBusinessRecordWidget = ({ onSubmit }: Props) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Add a new company</Button>
      <FormDialog<CompanyRecordEditFormValues>
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        header="Add a new company"
        initialValues={{ tenantId: tenantId } as CompanyRecordEditFormValues}
        onSubmit={async (values) => {
          try {
            await onSubmit(convertFromValuesToPayload(values));
            setModalOpen(false);
          } catch (e) {
            console.log('Error: ', e);
          }
        }}
      >
        <CompanyRecordEditForm edit editWithId />
      </FormDialog>
    </>
  );
};
