import { KycRecord } from 'api';
import {
  Table,
  TableFilterChangeFn,
  TableInitialFilters,
  TableQuickFilters,
} from 'components';
import { useHistory } from 'react-router-dom';

type BusinessKYCRecordType = { [key: string]: any } & KycRecord & any;

interface CompaniesTableProps {
  data: KycRecord[];
  onFiltersChange: TableFilterChangeFn;
  initialFilters: TableInitialFilters;
  quickFilters?: TableQuickFilters;
  pageSize: number;
  pageIndex: number;
  count: number;
}

export function CompaniesTable(props: CompaniesTableProps) {
  const history = useHistory();

  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      Filter: undefined,
    },
    {
      Header: 'Name',
      accessor: 'companyName',
      Filter: undefined,
    },
  ];

  return (
    <Table<BusinessKYCRecordType>
      name="BusinessKYCRecord Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.data}
      columns={columns}
      count={props.count}
      onClick={(row) =>
        history.push(`/tenants/${row.original.tenantId}/companies/${row.original.id}`)
      }
    />
  );
}
