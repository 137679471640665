import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FieldProps } from 'formik';
import React, { ChangeEvent, FocusEvent, useCallback } from 'react';

import { withFormLabel } from './withFormLabel';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    min?: number;
    max?: number;
  };

export const Input = withFormLabel(
  ({ disabled, multiline, field, min, max, error = false, type, ...props }: Props) => {
    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (field.onChange) field.onChange(e);
        if (props.onChange) props.onChange(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field.onChange, props.onChange],
    );

    const onBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (field.onBlur) field.onBlur(e);
        if (props.onBlur) props.onBlur(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field.onBlur, props.onBlur],
    );
    return (
      <TextField
        multiline={multiline}
        disabled={disabled}
        error={error}
        size="small"
        variant="outlined"
        inputProps={{
          min,
          max,
        }}
        type={type}
        {...props}
        {...field}
        onChange={onChange}
        onBlur={onBlur}/>
    );
  },
);
