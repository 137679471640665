import { PropsWithChildren } from 'react';
import { Formik, Form, FormikConfig, FormikValues } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Typography,
  Breakpoint,
} from '@mui/material';
import { MdClose } from 'react-icons/md';

type Props<T> = FormikConfig<T> & {
  open: boolean;
  onClose?: () => void;
  size?: Breakpoint;
  header?: string;
  cancelBtnName?: string;
  confirmBtnName?: string;
};

export const FormDialog = <T extends FormikValues>(props: PropsWithChildren<Props<T>>) => {
  const {
    open,
    onClose,
    size = 'md',
    header,
    cancelBtnName = 'Cancel',
    confirmBtnName = 'Confirm',
    children,
    ...formikProps
  } = props;

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth={size}>
      <Formik<T> validateOnChange={false} {...formikProps}>
        <Form>
          <DialogTitle>
            <Typography variant="subtitle1">{header}</Typography>
            <IconButton onClick={() => onClose?.()}>
              <MdClose />
            </IconButton>
          </DialogTitle>

          <DialogContent>{children}</DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={onClose}>
              {cancelBtnName}
            </Button>
            <Button color="primary" type="submit">
              {confirmBtnName}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
